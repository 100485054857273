<template>
    <div :class="$style.board">
        <div v-if="!passwordChanged">
            <el-row class="header">
                <el-col :class="$style.title">
                    change password
                </el-col>
            </el-row>
            <el-row class="mt-4 mb-0 pb-0">
                <dg-text-field
                    class="mb-3"
                    :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
                    :field_span="8"
                    :label_span="24"
                    field_mark="required"
                    label="old_password"
                    name="old_password"
                    placeholder=""
                    rules="required"></dg-text-field>

                <dg-text-field
                    class="mb-0"
                    :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
                    :field_span="8"
                    :label_span="24"
                    field_mark="required"
                    label="new_password"
                    name="new_password"
                    placeholder=""
                    rules="required"></dg-text-field>
            </el-row>
            <el-row class="mt-5">
                <el-col :span="24" class="border-top" v-loading="loadingRequests">
                    <el-col :class="$langConfig.cssClass" :span="20" class="mt-4">
                        <dg-label-field class="error-field-validate-message" label="fields_do_not_match_the_required_criteria"
                                        v-show="!this.validData"></dg-label-field>
                    </el-col>
                    <el-col :span="4" class="pt-3 text-right">
                        <dg-button
                            :click="createModule"
                            :text="actionButtonLabel"
                            :type="actionButtonType"
                            icon="el-icon-plus mr-2"></dg-button>
                    </el-col>
                </el-col>
            </el-row>
        </div>
        <div v-else>
            <h5 class="text-success">Password Changed <i class="el-icon-circle-check text-success"></i> </h5>
        </div>
    </div>
</template>

<script>

    import fieldController from 'devegram-vue-data-collecter-eui/src/mixins/fieldController';
    import module from './../index.js'
    import iziToast from 'izitoast'

    export default {
        mixins: [fieldController],
        props: {
            header: {
                required: false,
                default: false
            },
            editMode: {
                required: false,
                default: false
            }
        },
        data () {
            return {
                passwordChanged: false,
                control: this,
                module: {
                    ...module
                },
            }
        },
        computed: {
            ...$mapState('ajaxManager', ['loadingRequests']),
            actionButtonLabel: function () {
                if (!this.editMode) {
                    return 'edit';
                } else {
                    return 'edit'
                }
            },
            actionButtonType: function () {
                if (!this.validData) {
                    return 'danger';
                } else {
                    return 'primary app-bg-primary'
                }
            }
        },
        methods: {
            createModule() {
                this.validateFields().then(result => {
                    let context = this;
                    let data = {data: this.dataModel};
                    if (this.editMode) {
                        data.id = this.dataToBind.id;
                    }

                    if (result) {
                        let api = (this.editMode) ? 'changeLoggedInUserPassword' : 'changeLoggedInUserPassword';
                        this.$store.dispatch(this.module.info.name + '-api/' + api, data).then(response => {
                            iziToast.success({
                                title: this.$ml.get('password_changed'),
                                message: '',
                                position: 'topRight',
                                close: false,
                                timeout: 1900,
                                animateInside: true
                            });

                            this.amReady = false
                            this.passwordChanged = true
                            this.fields['old_password'].field.field_value = ''
                            this.fields['old_password'].field.updateValue()
                            this.fields['old_password'].field.validateMessage = ''

                            this.fields['new_password'].field.field_value = ''
                            this.fields['new_password'].field.updateValue()
                            this.fields['new_password'].field.validateMessage = ''

                            if (context.editMode) {
                                context.onEditCallback(response.data)
                            } else {
                                $vue.nextTick(() => {
                                    context.amReady = true
                                })
                                if (context.onCreateCallback) {
                                    context.onCreateCallback(response.data)
                                }
                            }
                        }).catch(error => {
                            iziToast.warning({
                                title: this.$ml.get('failed'),
                                message: error.response.data,
                                position: 'topRight',
                                close: false,
                                timeout: 1900,
                                animateInside: true
                            });
                            console.log('catched error', error)
                            this.setfieldsErrorMessage({
                                fieldsErrors: error.response.data
                            }, this.arefieldsValid);
                        })
                    }
                })
            }
        }
    }
</script>

<style lang="scss" module>
    .board {
        background-color: #fff;
        padding: 20px;
        border-radius: 14px;
        border: 1px solid #F2F2F0;
        box-shadow: 0 0 2px #A7B8D9;
        width: 100%;

        .header {
            border-bottom: 1px solid #F2F2F0;
            margin-bottom: 20px;
        }
    }

    .title {
        font-size: 14pt;
        font-family: Roboto, Verdana;
        text-transform: capitalize;
        letter-spacing: 0.6pt;
        color: #404244;
        line-height: 20pt;
    }
</style>
